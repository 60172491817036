import * as React from 'react';
import { PageProps } from 'gatsby';

import PresentationLayout from '~/components/layout/Presentation';

export default function typescriptAboutWhyUseTypescript({ location: pLocation }: PageProps) {
  return (
    <PresentationLayout pathname={pLocation.pathname}>
      <div className="slides">
        <section>
          <div>
            <img
              src="/img/presentation/typescript/logo.png"
              alt="logo"
              style={{ width: '50%', margin: '0 auto', background: 'none', border: 'none' }}
            />
            <p style={{ margin: '20px 0 0 0' }}>기초부터 시작하는 타입스크립트 강의</p>
            <p style={{ margin: '20px 0 0 0' }}>타입스크립트란? - 타입스크립트를 꼭 배워야 하나요?</p>
          </div>
          <aside className="notes"></aside>
        </section>

        <section>
          <div>
            <h2>타입스크립트란?</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/img/presentation/typescript/about/why-use-typescript/what-is-typescript.jpg" />
            </div>
            <aside className="notes">
              1. 자바스크립트에 타입스크립트를 추가함으로써 에디터와 강하게 결합합니다. ts는 js 위에 사용하는
              슈퍼셋입니다. ts만 가지고 개발할 수 있는건 아니고, js 문법에 타입을 추가할 수 있게 도와주는 도구입니다.
              이런 타입스크립트의 설정 파일을 통해, 타입 언어와 비슷한 특성을 가지게 해줍니다. 변수나 함수를 사용하는
              부분에서 해당 변수, 함수를 클릭하게 되면 정의가 되어있는 부분으로 이동합니다. 이런 타입 언어의 특성을
              에디터가 비슷하게 지원해주는데요. 이렇게 어디서 사용, 정의가 되어있는지 알 수 있기 때문에 에디터에서
              빠르게 에러를 찾고, 알려주게 됩니다. <br />
              2. 타입스크립트 코드는 원하는 어느 곳이든 맞춰서 변환시켜줄 수 있다는 것입니다. 브라우저, nodejs, deno
              등등, ts를 사용할 수 있는 곳은 아주 많은데요. 원래는 js만 사용할 수 있었지만, 이제 ts는 js를 사용할 수
              있는 어느 곳에서든 사용할 수 있습니다. <br />
              3. 타입스크립트는 별도의 추가 코드 없이 자바스크립트를 이해하고 타입추론을 통해 유저에게 좋은 경험을
              줍니다. 이게 어떤 얘기냐면, js를 이해하고 사용할 수 있다면 ts에는 any라는 타입이 있습니다. 어떤 타입이
              들어와도 사용할 수 있다는 의미의 타입인데 이런 any라는 타입으로 전체를 정의하거나, 타입을 정의하지 않아도
              에러가 발생하지 않도록 해주는 설정값을 추가해서 손쉽게 ts를 추가할 수 있고, 기존에 있던 프로젝트에도
              점직적 적용이 가능합니다.
            </aside>
          </div>
        </section>

        <section>
          <div>
            <h2>자바스크립트와 타입스크립트</h2>
            <ul style={{ display: 'flex' }}>
              <li style={{ width: '50%' }}>
                자바스크립트
                <ul>
                  <li>
                    동적 타입 언어
                    <div>
                      <img src="/img/presentation/typescript/about/why-use-typescript/java-code.jpg" />
                    </div>
                  </li>
                </ul>
              </li>
              <li style={{ width: '50%' }}>
                타입스크립트
                <ul>
                  <li>
                    정적 타입 언어
                    <div>
                      <img src="/img/presentation/typescript/about/why-use-typescript/javascript-code.jpg" />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <aside className="notes">
            동적 타입 언어 - 정적 언어인 자바를 예로 들어보자면 compile 시점에 타입이 결정됩니다. 전체를 실행시켜보고
            타입을 결정하는 것이 아니기 때문에 처음부터 타입을 지정해주어야 하고, 에디터에서 타입이 맞지 않은 경우
            바로바로 에러인 케이스들을 알려줍니다.
            <br />
            정적 타입 언어 - 타입스크립트는 자바스크립트를 자바처럼 쓸 수 있게 해준다고 생각하시면 됩니다. compile
            시점에 타입을 체크하고, 빌드할땐 타입을 걷어내서 자바스크립트 코드만 남게해주죠. 전체를 실행시켜보고 타입을
            결정하는 것이 아니기 때문에 처음부터 타입을 지정해주어야 하고, 에디터에서 타입이 맞지 않은 경우 바로바로
            에러인 케이스들을 알려줍니다.
            <br />
            --코드에 대한 설명--
          </aside>
        </section>

        <section>
          <h2>왜 타입스크립트를 꼭 배워야 할까요?</h2>
          <ol>
            <li>이해</li>
            <li>안정성</li>
            <li>생산성</li>
          </ol>
          <div>
            <img src="/img/presentation/typescript/about/why-use-typescript/javascript-code.jpg" />
          </div>
          <aside className="notes">
            1. 이해
            <br />
            협업을 할 때, 팀원이 짠 코드의 의도를 더 분명히 알 수 있습니다.
            <br />
            2. 안정성
            <br />
            타입이 run time 이전에 지정되기 때문에 에디터에서 에러가 바로바로 노출됩니다. 또한, 타입을 지정함으로써 다른
            타입의 값을 넣었을 때 문제가 있다는 경고를 보여줍니다.
            <br />
            3. 생산성
            <br />
            3-1. 타입스크립트는 자바스크립트이 슈퍼셋 입니다. 그렇기 때문에 유저가 넣고싶은 부분에만 넣으면 됩니다. 물론
            다 넣도록 강제할수도 있습니다. 게다가 처음부터 100% 커버할 필요도 없고 자바스크립트와 혼용이 가능합니다.
            초보자라도 코드에 하나하나씩 적용해볼 수 있죠. 3-2. `타입 정의 때문에 코드가 늘어나지 않나요` 라는 생각을 할
            수 있습니다. 타입 정의 코드가 늘어나도 코드의 복잡도가 올라갔을 때, 타입의 양보다 훨씬 더 효율을 높일 수
            있습니다. 게다가 리얼 환경에 배포하기 전 build를 하면 타입스크립트에서 자바스크립트로 결과물이 나오면서
            타입들은 모두 지워지게 됩니다. 불필요한 코드를 남겨둘 필욘 없으니까요. 혹시나 라이브러리를 만드는거라면
            남겨두는 옵션을 줄수도 있습니다.
          </aside>
        </section>

        <section>
          <h1>끝</h1>
          <div>감사합니다.</div>
        </section>
      </div>
    </PresentationLayout>
  );
}
